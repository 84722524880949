export const NavbarData = {
  en: {
    links: [
      { path: '/', name: 'HOME' },
      // { path: '/events', name: 'EVENTS' },
      { path: '/gallery', name: 'GALLERY' },
      { path: '/booking', name: 'BOOKING' },
   
    ],
  },

  fr: {
    links: [
      { path: '/fr', name: 'ACCUEIL' },
      // { path: '/fr/events', name: 'ÉVÉNEMENTS' },
      { path: '/fr/gallery', name: 'GALERIE' },
      { path: '/fr/booking', name: 'RESERVER' },
    ],
  },
};

export const FooterLinks = {
  en: {
    links: [
      { path: '/', name: 'HOME' },
      { path: '/gallery', name: 'GALLERY' },
      { path: '/booking', name: 'BOOKING' },
      { path: '/events', name: 'EVENTS' },
      { path: '/jobs', name: 'JOBS' },
      { path: '/press-kit', name: 'PRESS KIT' },
    ],
  },

  fr: {
    links: [
      { path: '/fr', name: 'ACCUEIL' },
      { path: '/fr/gallery', name: 'GALERIE' },
      { path: '/fr/booking', name: 'RESERVER' },
      { path: '/fr/events', name: 'ÉVÉNEMENTS' },
      { path: '/fr/jobs', name: 'JOBS' },
      { path: '/fr/press-kit', name: 'KIT PRESSE' },
    ],
  },
};
